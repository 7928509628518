:root {
  --white: 0 0% 100%;
  --black: 0 0% 0%;
  --background: 0 0% 100%;
  --foreground: 0 0% 15%;
  --heading: 209 50% 40%;
  --separator: 0 0% 73%;
  --selected: 209 61% 92%;
  --input-border: 0 0% 73%;
  --primary: 209 50% 40%;
  --primary-foreground: 0 0% 100%;
  --secondary: 30 100% 60%;
  --secondary-foreground: 0 0% 100%;
  --successful: 105 59% 45%;
  --successful-foreground: 0 0% 100%;
  --warning: 38 92% 50%;
  --warning-foreground: 0 0% 100%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 0 0% 100%;
  --informative: 199 100% 33%;
  --informative-foreground: 0 0% 100%;
  --muted: 0 0% 96%;
  --muted-foreground: 0 0% 15%;
  --wp--preset--spacing--50: theme("spacing.1");
  --wp--preset--spacing--60: theme("spacing.2");
  --wp--preset--spacing--70: theme("spacing.3");
  --wp--preset--spacing--80: theme("spacing.4");
  --wp--preset--spacing--90: theme("spacing.5");
  --wp--preset--spacing--100: theme("spacing.6");
  --wp--preset--spacing--110: theme("spacing.7");
  --wp--preset--spacing--120: theme("spacing.8");
  --wp--preset--spacing--130: theme("spacing.9");
  --wp--preset--spacing--140: theme("spacing.10");
  --wp--preset--spacing--150: theme("spacing.11");
  --wp--preset--spacing--160: theme("spacing.12");
}
