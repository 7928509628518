@font-face {
  font-display: swap;
  font-family: Questrial;
  font-style: normal;
  font-weight: 400;
  src: url("@fonts/questrial.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Kanit;
  font-style: normal;
  font-weight: 500;
  src: url("@fonts/kanit.woff2") format("woff2");
}
